@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.letters-list {
  .hbh-page-title {
    margin: 25px 0 0;
    padding: 0;
  }

  .total-info {
    color: $color-grey-light-2;
    border-bottom: 1px solid rgba(255, 255, 255, 0.37);
    margin: $spacer-1 0 $spacer-3;
    font-size: 14px;
  }

  .filters {
    display: flex;
    margin-top: 0;
    background-color: darken($color-grey-dark-2, 2%);
    padding: 15px 20px;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 20px;

    .hbh-select-label {
      color: #fff;
    }

    .dropdown-filter {
      width: 200px;
      .hbh-select {
        height: auto;
      }
    }

    .dates-filter {
      display: flex;
      flex-direction: column;
      margin: 0 20px;

      .ant-picker {
        padding: 4px 11px 3px;
        border-color: #fff;
        background: transparent;
        box-shadow: none;
        color: #fff;

        .ant-picker-suffix {
          svg {
            fill: #fff;
          }
        }

        .ant-picker-input > input {
          color: #fff;
          font-size: 12px;
          text-align: center;
        }
      }
    }

    .reset-button {
      align-self: end;
      cursor: pointer;
      font-size: 14px;
      width: max-content;
      color: $color-blue-primary;
      text-decoration: underline;
      flex-shrink: 0;
      margin: 0 20px 5px;
    }

    .generate-button {
      margin-left: auto;
    }
  }
}

.button-td-wrapper {
  .hbh-btn {
    width: 100%;
  }
}

.letters-table .hbh-table-dark .ant-table-content {
  thead {
    th {
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: center;
      }
    }
  }

  tbody {
    td {
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: center;
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        padding: 0;
      }
    }
  }
}

.button-error-pdf {
  @include applyButtonColor(#a36200);
}

.letter-action-icon {
  width: 18px;
  height: 18px;
  fill: $color-loblolly;
  transition: 0.2s all linear;

  &:hover {
    fill: $color-white;
  }
}

.letter-signatures {
  border: 1px solid $color-red-dark-1;
  border-radius: 4px;
  text-align: center;
  line-height: 28px;
  display: flex;
  justify-content: flex-end;

  .signatures-text {
    width: 34%;
  }

  .signatures-tooltip {
    width: 34%;
  }

  &.full {
    border-color: $color-green-grass;
  }

  &.offline {
    border-color: $color-grey-middle-1;
    color: $color-grey-middle-1;
  }
}

.button-sign {
  width: 100%;
}
