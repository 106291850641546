@import '@assets/styles/variables.scss';

.login-container {
  align-items: center;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    background-color: $color-black;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .login-panel {
    align-items: center;
    background: url('../../assets/img/default/login_mask.png') no-repeat left top;
    background-color: $color-grey-middle-5;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    margin-top: -10%;
    min-height: 240px;
    overflow: hidden;
    padding: 38px 90px 32px;
    position: relative;
    width: 500px;
    z-index: 1;

    .row {
      justify-content: space-between;
    }

    .login-greeting {
      color: $color-white;
      font-family: inherit;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.37;
      letter-spacing: normal;
      display: flex;
      flex-direction: column;
      text-align: center;

      strong {
        font-weight: 700;
      }
    }

    .login-loading {
      margin-top: 20px;
    }

    .login-link {
      margin-top: 20px;
      font-size: 20px;
    }
  }
}
